import React, {JSX, useCallback, useEffect} from "react";
import {Editor, Monaco, useMonaco} from "@monaco-editor/react";
import {editor} from "monaco-editor";
import {registerAutocomplete} from "./autocomplete";


interface CodeEditorProps {
    onChange?: (value: string) => void;
}

export function CodeEditor({onChange}: CodeEditorProps): JSX.Element {
    const ref = useMonaco();

    // @ts-ignore
    const handleEditorChange = useCallback((value, event) => {
        // here is the current value
        // console.log(value);
        if (onChange) {
            onChange(value);
        }
    }, [onChange]);

    const handleEditorDidMount = useCallback((ed: editor.IStandaloneCodeEditor, monaco: Monaco) => {
        console.log("onMount: the editor instance:", ed);
        console.log("onMount: the monaco instance:", monaco);
        // @ts-ignore
        window.ed = ed;
    }, []);

    const handleEditorWillMount = useCallback((monacoInstance: Monaco) => {
        console.log("beforeMount: the monaco instance:", monacoInstance);
    }, []);

    const handleEditorValidation = useCallback((markers: editor.IMarker[]) => {
        console.log("onValidate:", markers);
        // model markers
        markers.forEach((marker) => {
            console.log("onValidate:", marker.message);
        });
    }, []);

    useEffect(() => {
        if (ref) {
            console.log("here is the monaco instance:", ref);
            registerAutocomplete(ref);
        }
    }, [ref]);

    return (
        <div className="border-2 border-black rounded-md p-4">
            <Editor
                height="60vh"
                theme="vs-dark"
                defaultLanguage="python"
                defaultValue={`
import pandas as pd 

x = 10

print(x)


                `.trim()}
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                beforeMount={handleEditorWillMount}
                onValidate={handleEditorValidation}
            />
        </div>
    );
}

export const CodeEditorMemo = React.memo(CodeEditor);
