import React, {JSX} from "react";


interface CodeResultProps {
    output: string;
    error: string;
}

export function CodeResult({output, error}: CodeResultProps): JSX.Element {
    console.log(output, error);

    if (error) {
        const errorLines = error.split("\n");
        return (
            <div
                className="flex flex-col gap-2 my-2 p-2 border border-red-500 rounded-md monospace text-sm"
            >
                {errorLines.map((line, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                        {line}
                    </div>
                ))}
            </div>
        );
    }

    const lines = output.split("\n");

    return (
        <div>
            {lines.map((line, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                    {line}
                </div>
            ))}
        </div>
    );
}
