import React, {useEffect, useState} from "react";
import {createIdFromText} from "src/primitives/Markdown/utils";
import {cva} from "cva";


const listItemClasses = cva({
    base: "block border-l py-2 px-4 transition-all",
    variants: {
        state: {
            normal: "transparent text-white/70 border-white/10 hover:bg-white/5 hover:border-white/20",
            active: "bg-white/10 text-white/90 border-lime-400"
        }
    }
});


interface Heading {
    id: string;
    text: string | null;
    level: number;
}


function getHeaderElements(parentElementId: string): Element[] {
    // Get all h1-h4 elements
    const pageElement = window.document.getElementById(parentElementId);
    if (!pageElement) {
        return [];
    }
    return Array.from(pageElement.querySelectorAll("h1, h2, h3, h4"));
}

function getElement(elementId: string): Element | null {
    return window.document.getElementById(elementId);
}


function scrollToHeader(id: string) {
    const element = getElement(id);
    if (element) {
        element.scrollIntoView({behavior: "smooth"});
    }
}


export const TableOfContents = ({elementId}: {elementId: string}) => {
    const [headings, setHeadings] = useState<Heading[]>([]);
    const [activeId, setActiveId] = useState<string>("");

    useEffect(() => {
        const headerElements = getHeaderElements(elementId);
        // Process each heading
        const headingItems: Heading[] = headerElements.map((element) => {
            return {
                id: element.id || createIdFromText(element.textContent),
                text: element.textContent,
                level: window.parseInt(element.tagName.charAt(1))
            };
        });

        setHeadings(headingItems);
    }, [elementId]); // Empty dependency array means this runs once on mount

    useEffect(() => {
        // Keep track of all visible headers
        const visibleHeaders = new Set<string>();
        const headerElements = getHeaderElements(elementId);

        const callback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    visibleHeaders.add(entry.target.id);
                } else {
                    visibleHeaders.delete(entry.target.id);
                }
            });

            // Find the first visible header from our ordered list of headings
            const firstVisibleHeader = headerElements.find((heading) => visibleHeaders.has(heading.id));

            if (firstVisibleHeader) {
                setActiveId(firstVisibleHeader.id);
            }
        };

        const observer = new IntersectionObserver(callback, {
            // Small top margin to account for fixed header
            rootMargin: "-80px 0px -20% 0px",
            threshold: [0, 1]
        });

        headerElements.forEach((element) => observer.observe(element));
        return () => observer.disconnect();
    }, [elementId]); // Added headings as dependency since we use it in the callback

    return (
        <nav className="p-4 w-full">
            <h2 className="text-lg font-bold mb-2">
                Table of Contents
            </h2>
            <ul className="text-sm">
                {headings.map((heading) => (
                    <li
                        key={heading.id}
                    >
                        <a
                            href={`#${heading.id}`}
                            onClick={scrollToHeader.bind(null, heading.id)}
                            className={listItemClasses({state: activeId === heading.id ? "active" : "normal"})}
                        >
                            <span
                                className="block"
                                style={{marginLeft: `${(heading.level - 1) * 1.5}rem`}}
                            >
                                {heading.text}
                            </span>
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};
