import {Monaco} from "@monaco-editor/react";
import pandasCompletions from "./pandas-completions.json";


export function registerAutocomplete(monaco: Monaco) {
    // Set completion items
    const keywords = [
        "def", "class", "import", "from", "for", "while", "if", "else", "elif",
        "try", "except", "finally", "with", "as", "return", "yield", "break",
        "continue", "pass", "and", "or", "not", "is", "in", "lambda"
    ];
    const completionItems = keywords.map((keyword) => ({
        label: keyword,
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: keyword
    }));

    // Register a completion item provider for the Python language
    monaco.languages.registerCompletionItemProvider("python", {
        // @ts-ignore
        provideCompletionItems: (model, position, context) => {
            const lineContent = model.getLineContent(position.lineNumber);
            const word = model.getWordUntilPosition(position);

            const suggestions = [];

            // console.log(word);
            // console.log(lineContent);

            if (lineContent === "" || true) {
                const range = new monaco.Range(
                    position.lineNumber,
                    word.startColumn,
                    position.lineNumber,
                    word.endColumn
                );
                keywords.forEach((keyword) => {
                    suggestions.push({
                        label: keyword,
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: keyword,
                        range,
                        documentation: "",
                        insertTextRules: monaco.languages.CompletionItemInsertTextRule.None
                    });
                });
            }

            if (lineContent.includes("pd.")) {
                // Adjust range for dot completion
                const dotRange = new monaco.Range(
                    position.lineNumber,
                    word.startColumn,
                    position.lineNumber,
                    word.endColumn
                );

                // Add DataFrame
                suggestions.push({
                    label: "DataFrame",
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertText: "DataFrame",
                    range: dotRange,
                    documentation: "DataFrame",
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.None
                });

                // Add other pd.* functions
                Object.entries(pandasCompletions.pd).forEach(([key, value]) => {
                    if (key !== "DataFrame") {
                        suggestions.push({
                            label: key,
                            kind: monaco.languages.CompletionItemKind.Function,
                            // @ts-ignore
                            insertText: `${key}`,
                            range: dotRange,
                            // @ts-ignore
                            documentation: value.signature,
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.None
                        });
                    }
                });
            }
            return {suggestions};
        }
    });
}
