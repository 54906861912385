import React from "react";
import {MarkdownContent} from "src/primitives/Markdown/index.jsx";
import {PageMeta} from "src/pages/Web/components/PageMeta";
import {LandingPageWrapper} from "src/pages/Web/pages/LandingPages/LandingPageWrapper";
// @ts-ignore
import content from "./content.md?raw";


export function CsvToApiPage() {
    return (
        <>
            <PageMeta
                title="CSV to API"
                description={`
                How to create an API from your CSV file.
                `}
            />
            <LandingPageWrapper>
                <MarkdownContent content={content}/>
            </LandingPageWrapper>
        </>
    );
}
