import {useCallback} from "react";
import {ApolloError, gql, useMutation} from "@apollo/client";


const MUTATION = gql`
    mutation RunCode($code: String!) {
        runCode(code: $code) {
            ok
            errorCode
            result
        }
    }
`;

type UseRunCodeReturn = [
    (code: string) => Promise<object>, // Adjust `any` to match `runCode`'s actual type
    {
        loading: boolean;
        error?: ApolloError | undefined;
        result?: object; // Adjust this type if `runCode` has a specific shape
    }
];

export function useRunCode(): UseRunCodeReturn {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);
    const doMutate = useCallback((code: string) => {
        return mutation({
            variables: {code}
        }).then((response) => {
            return response.data.runCode;
        });
    }, [mutation]);

    return [doMutate, {loading, error, result: data?.runCode?.result}];
}
