import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

const GET_ALL_TABLE_STATS_QUERY = gql`
    query GetAllTableStats($id: String!) {
        table (tableId: $id) {
            id
            allStats {
                id
                version
                data
            }
        }
    }
`;

export function useGetAllTableStats(tableId) {
    const options = {variables: {id: tableId}};
    const {data, loading, error, refetch} = useQuery(GET_ALL_TABLE_STATS_QUERY, options);

    const allStats = useMemo(() => {
        return data?.table?.allStats || [];
    }, [data]);

    return {
        data: allStats, loading, error, refetch
    };
}
