import React from "react";
import {loadable} from "react-lazily/loadable";
import PropTypes from "prop-types";
import {createBrowserRouter, Navigate} from "react-router-dom";
import {SignedIn, SignedOut} from "@clerk/clerk-react";
import {LINKS} from "src/links.js";
import {SignInPage} from "src/pages/SignIn/index.jsx";
import {SignOutPage} from "src/pages/SignOut/index.jsx";
import {SignUpPage} from "src/pages/SignUp/index.jsx";
import {webRoutes} from "src/pages/Web/routes.jsx";
import {StartPage} from "src/pages/StartPage/index.jsx";
import {RequireServerReady} from "src/pages/components/RequireServerReady/index.jsx";
import {CreateWorkspacePage} from "src/pages/CreateWorkspace/index.jsx";
import {Home} from "src/pages/Home/index.jsx";
import {WebPage} from "src/pages/Web/index.jsx";
import {FullScreenLoading} from "src/components/FullScreenLoading/FullScrenLoading.jsx";


const {AdminPage} = loadable(() => import("src/pages/Admin/index.jsx"), {
    fallback: <FullScreenLoading/>
});
const {RenderPublicPage} = loadable(() => import("src/pages/Public/index.jsx"), {
    fallback: <FullScreenLoading/>
});


const RequireSignedIn = ({children}) => (
    <RequireServerReady>
        <SignedIn>
            {children}
        </SignedIn>
        <SignedOut>
            <Navigate to={LINKS.SIGN_IN} />
        </SignedOut>
    </RequireServerReady>
);

RequireSignedIn.propTypes = {
    children: PropTypes.node
};


export const routes = createBrowserRouter([{
    path: `${LINKS.SIGN_IN}/*`,
    element: (
        <>
            <SignedIn>
                <Navigate to={LINKS.HOME} />
            </SignedIn>
            <SignedOut>
                <SignInPage />
            </SignedOut>
        </>
    )
}, {
    path: `${LINKS.SIGN_UP}/*`,
    element: (
        <SignUpPage />
    )
}, {
    path: `${LINKS.SIGN_OUT}/*`,
    element: <SignOutPage/>
}, {
    path: `${LINKS.CREATE_WORKSPACE}/*`,
    element: (
        <RequireServerReady>
            <SignedIn>
                <CreateWorkspacePage/>
            </SignedIn>
            <SignedOut>
                <Navigate to={LINKS.SIGN_IN} />
            </SignedOut>
        </RequireServerReady>
    )
}, {
    path: `${LINKS.START}/*`,
    element: (
        <RequireServerReady>
            <SignedIn>
                <StartPage/>
            </SignedIn>
            <SignedOut>
                <Navigate to={LINKS.SIGN_IN} />
            </SignedOut>
        </RequireServerReady>
    )
}, {
    path: `${LINKS.HOME}/*`,
    element: (
        <RequireServerReady>
            <SignedIn>
                <Home/>
            </SignedIn>
            <SignedOut>
                <Navigate to={LINKS.SIGN_IN} />
            </SignedOut>
        </RequireServerReady>
    )
}, {
    path: `${LINKS.PUBLIC}/:tableId?/*`,
    element: (
        <RequireServerReady>
            <RenderPublicPage/>
        </RequireServerReady>
    )
}, {
    path: `${LINKS.ADMIN}/*`,
    element: (
        <RequireSignedIn>
            <AdminPage/>
        </RequireSignedIn>
    )
}, {
    path: "",
    element: <WebPage />,
    children: webRoutes
}, {
    path: "*",
    element: (
        <Navigate to={LINKS.WEB} />
    )
}]);
