import React, {JSX} from "react";
import {useRunCode} from "src/api/functions/useRunCode";
import {Button} from "src/primitives/button";
import {CodeResult} from "src/pages/Home/pages/TablePage/pages/FunctionsPage/CodeResult";

interface ExecuteCodeProps {
    code: string;
}

export function ExecuteCode({code}: ExecuteCodeProps): JSX.Element | null {
    const [runCode, {loading, result}] = useRunCode();

    if (!code) {
        return null;
    }

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        runCode(code);
    };


    return (
        <div>
            {/* @ts-ignore */}
            <Button
                isLoading={loading}
                theme="primary"
                onClick={handleSubmit}
            >
                run!
            </Button>

            {result && (
                <div>
                    <CodeResult
                        // @ts-ignore
                        output={result?.output}
                        // @ts-ignore
                        error={result?.error}
                    />
                </div>
            )}
        </div>
    );
}
