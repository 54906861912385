import React, {JSX} from "react";
import styled from "styled-components";
import {TableOfContents} from "src/pages/Web/components/TableOfContent";


export const LandingPageWrapper = ({children}: { children: JSX.Element }) => {
    const pageId = "page-content";

    return (
        <Wrapper
            className="flex justify-between"
        >
            <div
                className="flex-shrink flex-grow basis-96 max-xl:hidden"
            />
            <article
                id={pageId}
                className="flex-grow max-w-full"
            >
                <div
                    className="px-4 lg:mx-auto lg:w-176 w-auto mx-10"
                >
                    {children}
                </div>
            </article>
            <div
                className="flex-shrink-0 flex-grow basis-96 max-xl:hidden pl-8"
            >
                <div
                    className="sticky top-24 w-96"
                >
                    <TableOfContents elementId={pageId}/>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  background: rgb(23 23 23);
  padding-top: 50px;
  margin-bottom: 0;
  padding-bottom: 160px;
  & h1 {
    line-height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;
