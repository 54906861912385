import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {SelectChartType} from "src/components/ChartBuilder/components/SelectChartType/index.jsx";
import {ChartOptions} from "src/components/ChartBuilder/components/ChartOptions/index.jsx";
import {AXIS_TYPES, CHART_TYPES} from "src/components/ChartBuilder/constants.jsx";
import {ErrorComponent} from "src/components/ErrorComponent/index";
import {SelectXaxis} from "src/components/ChartBuilder/components/SelectXaxis/index.jsx";
import {buildOptions} from "src/components/ChartBuilder/options.js";
import {EditSeries} from "src/components/ChartBuilder/components/EditSeries/index.jsx";
import {ControlWrapper} from "src/components/ChartBuilder/layouts.jsx";
import {arrayToMap} from "src/utils/misc.js";
import {getAxisTypeByColumn} from "src/components/ChartBuilder/utils.js";
import {Title} from "src/primitives/title.jsx";
import {ConfigSeriesItem} from "src/components/ChartBuilder/components/ConfigSeriesItem/index.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowLeft} from "react-icons/fi";
import {Checkbox} from "src/primitives/controls/Checkbox.jsx";
import {ResultPreview} from "src/components/ChartBuilder/components/ResultPreview/index.jsx";
import {emptySeries} from "src/components/ChartBuilder/components/EditSeries/utils.js";
import {TextEditable} from "src/primitives/controls/index.jsx";


export function ChartBuilder({
    columns,
    data,
    onChange,
    chartTitle,
    updateChartTitle,
    initialConfig,
    initialSeries
}) {
    const [activeSeriesId, setActiveSeriesId] = useState(null);
    const [series, setSeries] = useState(
        initialSeries || [emptySeries({column: "sold_price", name: "Sold price"})]
    );
    const [chartConfig, setChartConfig] = useState(initialConfig || {
        chartType: CHART_TYPES.SCATTER,
        titleText: "",
        yAxisLabel: "",
        yAxisType: AXIS_TYPES.VALUE,
        // X-axis!
        xAxis: "rooms",
        xAxisLabel: "",
        xAxisType: AXIS_TYPES.VALUE,
        // Other
        aggregateSeries: false
    });

    const columnsMap = arrayToMap(columns, "name");

    const handleSelectChartType = (chartType) => {
        setChartConfig((prevConfig) => ({...prevConfig, chartType}));
    };

    const handleUpdateChartOptions = (newOptions) => {
        setChartConfig((prevConfig) => Object.assign({}, prevConfig, newOptions));
    };

    const handleUpdateXaxis = (newAxis) => {
        const column = columnsMap[newAxis];
        if (!column) {
            return;
        }

        setChartConfig((prevConfig) => {
            return Object.assign({}, prevConfig, {
                xAxis: newAxis,
                xAxisLabel: newAxis,
                xAxisType: getAxisTypeByColumn(column)
            });
        });
    };

    const updateSeriesConfig = (id, newData) => {
        const newSeries = series.map((seriesItem) => {
            return (seriesItem.id === id) ? Object.assign({}, seriesItem, newData) : seriesItem;
        });
        setSeries(newSeries);
    };

    useEffect(() => {
        if (onChange) {
            onChange({config: chartConfig, series});
        }
    }, [onChange, chartConfig, series]);

    const activeSeries = series.find((item) => item.id === activeSeriesId);

    const options = useMemo(() => {
        // console.log("Rebuild options");
        const o = buildOptions(data, chartConfig, series);
        console.log(o);
        return o;
    }, [data, chartConfig, series]);


    return (
        <ErrorComponent>
            <div className="w-full h-full flex">
                <div className="w-112 border-r border-black/15 px-8 py-4">
                    <ControlWrapper>
                        <Title>
                            <TextEditable
                                text={chartTitle || "New chart"}
                                onSubmit={updateChartTitle}
                            />
                        </Title>
                    </ControlWrapper>

                    {activeSeriesId && (
                        <>
                            <ControlWrapper>
                                <Title size="xs">
                                    <div className="flex items-center gap-1 ml-[-8px]">
                                        <IconWrapper
                                            icon={<FiArrowLeft/>}
                                            onClick={setActiveSeriesId.bind(null, null)}
                                            size="xs"
                                        />
                                        <span>
                                            Edit {activeSeries.name}
                                        </span>
                                    </div>
                                </Title>
                            </ControlWrapper>

                            <ControlWrapper>
                                <ConfigSeriesItem
                                    item={activeSeries}
                                    updateSeriesConfig={updateSeriesConfig}
                                />
                            </ControlWrapper>
                        </>
                    )}

                    {!activeSeriesId && (
                        <>
                            <ControlWrapper>
                                <SelectChartType
                                    onSelectChartType={handleSelectChartType}
                                    value={chartConfig.chartType}
                                />
                            </ControlWrapper>

                            <ControlWrapper>
                                <ChartOptions
                                    onChange={handleUpdateChartOptions}
                                    config={chartConfig}
                                />
                            </ControlWrapper>

                            <ControlWrapper>
                                <SelectXaxis
                                    columns={columns}
                                    onChange={handleUpdateXaxis}
                                    value={chartConfig.xAxis}
                                />
                                <div className="my-1 text-sm">
                                    <Checkbox
                                        name="aggregate"
                                        text="Aggregate"
                                        isChecked={chartConfig.aggregateSeries}
                                        onChange={(n, v) => handleUpdateChartOptions({aggregateSeries: v})}
                                    />
                                </div>
                            </ControlWrapper>

                            <ControlWrapper>
                                <EditSeries
                                    columns={columns}
                                    series={series}
                                    updateSeries={setSeries}
                                    setActiveSeriesId={setActiveSeriesId}
                                    aggregateData={chartConfig.aggregateSeries}
                                />
                            </ControlWrapper>
                        </>
                    )}
                </div>

                <div className="flex-1">
                    <ResultPreview
                        options={options}
                        xAxis={chartConfig.xAxis}
                    />
                </div>

            </div>
        </ErrorComponent>
    );
}

ChartBuilder.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string
    })),
    data: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    chartTitle: PropTypes.string,
    updateChartTitle: PropTypes.func,
    initialConfig: PropTypes.object,
    initialSeries: PropTypes.arrayOf(PropTypes.object)
};
