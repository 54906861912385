import React, {JSX} from "react";
import {Helmet} from "react-helmet-async";


interface PageMetaProps {
    title: string;
    description?: string;
}

export function PageMeta({title, description}: PageMetaProps): JSX.Element {
    const pageUrl = window.location.origin + window.location.pathname;
    const formattedTitle = `${title} | rho.store`;

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{formattedTitle}</title>
            {description && (
                <meta name="description" content={description} />
            )}
            <link rel="canonical" href={pageUrl} />
        </Helmet>
    );
}
