import React, {JSX, Fragment} from "react";
import styled from "styled-components";
import {UseCaseContent} from "./UseCaseContent";
import {useCaseContent} from "./content";


export function UseCases(): JSX.Element {
    const firstItem = useCaseContent[0];
    const [activeItem, setActiveItem] = React.useState<string>(firstItem.key);
    const width = 960;

    return (
        <div className="flex flex-col max-w-full bg-white-1">
            <Divider $size={width} className="max-w-full"/>
            {useCaseContent.map((item) => (
                <Fragment key={item.key}>
                    <div
                        className="mx-auto py-4 px-4 max-w-full"
                        style={{width}}
                    >
                        <UseCaseContent
                            title={item.title}
                            content={item.content}
                            isActive={item.key === activeItem}
                            setIsActive={setActiveItem.bind(null, item.key)}
                        />
                    </div>
                    <Divider
                        $size={width}
                    />
                </Fragment>
            ))}
        </div>
    );
}


const Divider = styled.div<{$size: number}>`
  height: 1px;
  width: 100%;
  background: linear-gradient(to right,
  rgba(255, 255, 255, 0.08),
  rgba(255, 255, 255, 0.4) calc(50% - ${({$size}) => $size / 2}px),
  rgba(255, 255, 255, 0.5) calc(50%),
  rgba(255, 255, 255, 0.4) calc(50% + ${({$size}) => $size / 2}px),
  rgba(255, 255, 255, 0.08)
  );
`;
