import {isNullOrUndefined} from "src/utils/misc.js";

export const DataTypes = {
    STRING: "STRING",
    INTEGER: "INTEGER",
    FLOAT: "FLOAT",
    BOOLEAN: "BOOLEAN",
    DATE: "DATE",
    DATETIME: "DATETIME"
};


export const VariableTypes = {
    CONTINUOUS: "CONTINUOUS",
    CATEGORICAL: "CATEGORICAL",
    TEXT: "TEXT",
    CONSTANT: "CONSTANT",
    IDENTIFIER: "IDENTIFIER",
    BINARY: "BINARY"
};

export const SemanticTypes = {
    PERCENTAGE: "PERCENTAGE",
    YEAR: "YEAR"
};


export const ALL_VARIABLE_TYPES = [
    VariableTypes.CONTINUOUS,
    VariableTypes.CATEGORICAL,
    VariableTypes.TEXT,
    VariableTypes.CONSTANT,
    VariableTypes.IDENTIFIER,
    VariableTypes.BINARY
];

export const TECHNICALLY_POSSIBLE_TYPES = {
    [DataTypes.STRING]: [
        VariableTypes.TEXT,
        VariableTypes.CATEGORICAL,
        VariableTypes.IDENTIFIER,
        VariableTypes.CONSTANT
    ],
    [DataTypes.INTEGER]: [
        VariableTypes.CONTINUOUS,
        VariableTypes.CATEGORICAL,
        VariableTypes.IDENTIFIER,
        VariableTypes.CONSTANT
    ],
    [DataTypes.FLOAT]: [
        VariableTypes.CONTINUOUS,
        VariableTypes.CATEGORICAL,
        VariableTypes.CONSTANT
    ],
    [DataTypes.BOOLEAN]: [
        VariableTypes.CATEGORICAL,
        VariableTypes.CONSTANT,
        VariableTypes.BINARY
    ],
    [DataTypes.DATE]: [
        VariableTypes.CONTINUOUS,
        VariableTypes.CATEGORICAL
    ],
    [DataTypes.DATETIME]: [
        VariableTypes.CONTINUOUS,
        VariableTypes.CATEGORICAL
    ]
};

export const SortDirection = {
    ASC: "ASC",
    DESC: "DESC"
};

export function getNewDirection(existingDirection) {
    if (!existingDirection) {
        // Default
        return SortDirection.ASC;
    }
    return existingDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
}

export function getDirection({desc} = {}) {
    if (isNullOrUndefined(desc)) {
        return null;
    }
    return desc ? SortDirection.DESC : SortDirection.ASC;
}

export function isColumnNumeric({dataType}) {
    return dataType.startsWith("INT") || dataType.startsWith("FLOAT");
}

export function isColumnInteger({dataType}) {
    return dataType.startsWith("INT");
}

export function isColumnText({dataType, variableType}, checkVariable = false) {
    if (!checkVariable) {
        return dataType === DataTypes.STRING;
    }

    return dataType === DataTypes.STRING && variableType === VariableTypes.TEXT;
}

export function isColumnDate({dataType}) {
    return dataType.startsWith("DATE");
}

export function isColumnBoolean({dataType}) {
    return dataType === DataTypes.BOOLEAN;
}

export function isColumnContinuous({variableType}) {
    return variableType === VariableTypes.CONTINUOUS;
}

export function isColumnIdentifier({variableType}) {
    return variableType === VariableTypes.IDENTIFIER;
}

export function isColumnCategorical({variableType}) {
    return variableType === VariableTypes.CATEGORICAL;
}

export function isColumnConstant({variableType}) {
    return variableType === VariableTypes.CONSTANT;
}

export const SYSTEM_COLUMNS = [
    "_id", "_version", "_created_at"
];

export function isSystemColumn({name}) {
    return SYSTEM_COLUMNS.indexOf(name) > -1;
}

export function removeSystemColumns(columns) {
    return columns.filter((column) => !isSystemColumn(column));
}

export function firstValue(values) {
    for (let i = 0; i < values.length; i++) {
        if (values[i]) {
            return values[i];
        }
    }
    return null;
}

export function getIqrLimits(stats) {
    /**
     * https://medium.com/analytics-vidhya/removing-outliers-understanding-how-and-what-behind-the-magic-18a78ab480ff
     */
    const {p25, p75} = stats;
    const iqr = p75 - p75;
    const distance = 1.5;

    return {
        lower: p25 - distance * iqr,
        upper: p75 + distance * iqr
    };
}

export function formatDataFromQueryResult(columns, rows) {
    if (!Array.isArray(columns) || !Array.isArray(rows)) {
        return [];
    }

    return rows.map((row) => {
        return columns.reduce((acc, name, index) => {
            return Object.assign(acc, {[name]: row[index]});
        }, {});
    });
}

export function getNumericalColumnNames(columns) {
    if (!columns) {
        return [];
    }

    return columns.filter((column) => {
        return isColumnNumeric(column) && !isColumnIdentifier(column);
    }).map(({name}) => name);
}
