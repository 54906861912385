import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";


export function LiveDemo({demoUrl}) {
    const [showOverlay, setShowOverlay] = useState(true);

    useEffect(() => {
        window.setTimeout(() => {
            setShowOverlay(false);
        }, 500);
    }, []);

    return (
        <Wrapper
            className="rounded-md shadow-md shadow-white/25 border border-white/20 hover:border-white/30 transition-all"
        >
            <Iframe
                title="rho.store demo"
                src={`${demoUrl}&size=50&hideDownload=true`}
            />
            {showOverlay && (
                <Overlay/>
            )}
        </Wrapper>
    );
}

LiveDemo.propTypes = {
    demoUrl: PropTypes.string
};

const Wrapper = styled.div`
  position: relative;
  width: 1060px;
  max-width: 90%;
  height: 720px;
  margin: 0 auto;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  zoom: 0.8;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  backdrop-filter: blur(2px);
  background-image: linear-gradient(to top, rgba(9, 32, 63, 0.25) 0%, rgba(83, 120, 149, 0.25) 100%);
`;
