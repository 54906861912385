export function createIdFromText(textContent: string | null): string {
    if (!textContent) {
        return "";
    }
    return textContent
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "");
}

