import React, {ReactElement} from "react";
import {Title} from "src/primitives/title.jsx";


interface ErrorPageProps {
    content: ReactElement;
}


export function ErrorPage({content}: ErrorPageProps) {
    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="h-64 min-w-32">
                {/* @ts-ignore */}
                <Title>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    That's an error
                </Title>
                <div>
                    {content}
                </div>
            </div>
        </div>
    );
}
