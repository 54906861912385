import React, {useState} from "react";
import PropTypes from "prop-types";
import {useGetTableVersions} from "src/api/tables/useGetTableVersions.js";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {useGetAllTableStats} from "src/api/tableData/useGetAllTableStats.js";
import {CenteredContent, PageContent} from "src/primitives/layout/index.jsx";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {Title} from "src/primitives/title.jsx";
import {DialogModal} from "src/primitives/modals/Dialog.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {ColumnStatsVersionOverview} from "./components/ColumnStatsVersionOverview";
import {ColumnComparison} from "./components/ColumnComparison";


export function TableVersionsPage({tableId}) {
    const [selectedColumn, setSelectedColumn] = useState(null);
    const {table, loading} = useGetTable(tableId);
    const {versions, loading: loadingVersions} = useGetTableVersions(tableId);
    const {data, loading: loadingStats} = useGetAllTableStats(tableId);

    if (loading || loadingVersions || loadingStats) {
        return (
            <PageContent
                title="Versions"
                size="xl"
            >
                <CenteredContent>
                    <Spinner/>
                </CenteredContent>
            </PageContent>
        );
    }

    const columns = table?.columns || [];

    return (
        <PageContent
            title="Versions"
            size="xl"
        >
            <DialogModal
                close={setSelectedColumn.bind(null, null)}
                title={`Summary for column '${selectedColumn?.name}'`}
                isOpen={!!selectedColumn}
                size="2xl"
                verticalAlign="top"
            >
                {selectedColumn && (
                    <ColumnStatsVersionOverview
                        column={selectedColumn}
                        allVersionStats={data}
                    />
                )}
            </DialogModal>

            <EaseIn>
                <div>
                    Overview of all versions of this table.
                </div>
                <div className="mt-6 mb-4">
                    <Title size="small">
                        Columns overview
                    </Title>
                </div>
                <ColumnComparison
                    columns={columns}
                    stats={data}
                    versions={versions}
                    selectColumn={setSelectedColumn}
                />
            </EaseIn>
        </PageContent>
    );
}

TableVersionsPage.propTypes = {
    tableId: PropTypes.string.isRequired
};
