import React from "react";
import PropTypes from "prop-types";
import {
    ToggleColumnVisibility
// eslint-disable-next-line max-len
} from "src/pages/Home/pages/TablePage/pages/TableSettingsPage/components/ColumnSettings/components/ToggleColumnVisibility/index.jsx";


export function ColumnItem({tableId, column}) {
    return (
        <div className="py-2 px-3 rounded-md border border-gray-200 mb-2">
            {column.name}
            <ToggleColumnVisibility tableId={tableId} column={column}/>
        </div>
    );
}


ColumnItem.propTypes = {
    tableId: PropTypes.string.isRequired,
    column: PropTypes.shape({
        name: PropTypes.string.isRequired,
        variableType: PropTypes.string
    })
};
