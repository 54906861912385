import React from "react";
import {PageContent} from "src/primitives/layout";
import {CodeEditorMemo} from "./CodeEditor";
import {ExecuteCode} from "./ExecuteCode";


export function FunctionsPage(): React.JSX.Element {
    const [code, setCode] = React.useState<string>("");

    const handleChange = React.useCallback((value: string) => {
        setCode(value);
    }, [setCode]);

    return (
        <PageContent
            title="Function"
            subTitle={undefined}
            size={undefined}
            align={undefined}
        >
            <div className="flex flex-col gap-2">
                <div>
                    <ExecuteCode code={code}/>
                </div>
                <CodeEditorMemo onChange={handleChange}/>
            </div>
        </PageContent>
    );
}
