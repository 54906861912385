import React from "react";
import styled from "styled-components";
import {TextHighlight} from "src/pages/Web/components/TextHighlight.jsx";
import {Features} from "src/pages/Web/pages/Start/components/Features/index.jsx";
import {Ambient} from "src/pages/Web/components/Ambient/index.jsx";
import {FAQ} from "src/pages/Web/pages/Start/components/FAQ/index.jsx";
import {GalaxyWrapper} from "src/pages/Web/components/Galaxy/index.jsx";
import {About} from "src/pages/Web/sections/About/index.jsx";
import {StartIntro} from "src/pages/Web/pages/Start/components/Intro/index.jsx";
import {StartIntoV2} from "src/pages/Web/pages/Start/components/IntroV2/index.jsx";
import {FeaturesOverview} from "src/pages/Web/pages/Start/components/FeaturesOverview";
import {RhoDescription} from "src/pages/Web/pages/Start/components/RhoDescription";
import {PageMeta} from "src/pages/Web/components/PageMeta.tsx";
import {GettingStartedSteps} from "src/pages/Web/pages/Start/components/GettingStartedSteps";
import {UseCases} from "src/pages/Web/pages/Start/components/UseCases";


export function StartPage() {
    const webV2 = true;

    return (
        <>
            <PageMeta
                title="the lighweight data warehouse"
                description={`
                rho.store is a data platform for data scientists. 
                Store and manage your data in the cloud, with built-in data features.
                `}
            />

            <Ambient/>
            <GalaxyWrapper/>

            <Wrapper>
                <div className="max-w-96 text-center text-2xl mx-auto my-6 lg:my-8 lg:mt-10 appear-fast">
                    <TextHighlight theme="green">rho.store</TextHighlight>
                </div>

                {webV2 ? (
                    <>
                        <div className="appear">
                            <StartIntoV2/>
                        </div>

                        <div className="appear-slow">
                            <RhoDescription/>
                        </div>

                        <div className="appear-slow m-my-lg">
                            <div className="max-w-96 text-center text-2xl mx-auto max-sm:my-4 lg:my-8">
                                <TextHighlight theme="white">
                                    the basics
                                </TextHighlight>
                            </div>
                            <div className="m-my-lg">
                                <GettingStartedSteps/>
                            </div>
                        </div>

                        <div className="appear-slow m-my-lg">
                            <div className="max-w-96 text-center text-2xl mx-auto max-sm:my-4 lg:my-8">
                                <TextHighlight theme="white">
                                    features
                                </TextHighlight>
                            </div>
                            <div className="m-my-lg">
                                <FeaturesOverview/>
                            </div>
                        </div>

                        <div className="appear-slow m-my-lg">
                            <div className="max-w-96 text-center text-2xl mx-auto max-sm:my-4 lg:my-8">
                                <TextHighlight theme="white">
                                    use cases
                                </TextHighlight>
                            </div>
                            <div className="m-my-lg">
                                <UseCases/>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="appear">
                            <StartIntro/>
                        </div>
                        <div className="appear-slow">
                            <div className="max-w-96 text-center text-2xl mx-auto mt-8 lg:my-12">
                                <TextHighlight theme="white">features</TextHighlight>
                            </div>

                            <Features/>
                        </div>


                    </>
                )}

                <div className="my-10">
                    <About/>
                </div>

                <div className="my-10 w-192 max-w-full mx-auto px-8">
                    <FAQ/>
                </div>

            </Wrapper>
        </>
    );
}


const Wrapper = styled.div`
`;
