import React, {ReactElement} from "react";
import {ErrorBoundary} from "react-error-boundary";

interface ErrorComponentProps {
    children: React.ReactNode;
    fallback: ReactElement;
}

function DefaultFallback() {
    return <div>Something went wrong</div>;
}

export function ErrorComponent({children, fallback}: ErrorComponentProps) {
    return (
        <ErrorBoundary
            fallback={fallback || <DefaultFallback/>}
        >
            {children}
        </ErrorBoundary>
    );
}
